@import "../../common-styles.scss";

.delete-sale-evidence:hover:not([disabled]) {
  color: #506c88;
}

.delete-sale-evidence {
  border: none;
  color: $page-headings-text;
  background: none;
}

.sales-evidence-heading {
  background: #61dafb;
  min-height: 60px;
}

.sales-evidence-heading-text {
  padding-top: 20px;
  font-weight: bold;
}

.listing-preview {
  max-height: 300px;
}

@import "../../common-styles.scss";

.delete-option {
  //padding-top: 20px !important;
  cursor: pointer;
}

.currency-inputs {
  display: inline-flex;
}

.council-list {
  padding-left: 15px;
  display: inline-block;
}

.currency-item {
  padding-right: 5px;
}

.check-circle-icon {
  transform: scale(1.3);
  color: #00b2a9;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 15px;
}

.verify-icon-begin {
  padding-left: 10px;
  font-size: 25px;
}

.verified-circle-section {
  text-align: center;
  margin-right: 15px;
  margin-top: -10px;
}

.property-verified-text {
  text-align: center;
}

.verified-property-heading {
  object-fit: cover;

  span {
    font-family: $application-font;
    font-weight: bold;
  }
}

.delete-button-property-verify {
  border: none;
  background: none;
}

.delete-button-property-verify:hover,
.delete-button-property-verify:focus {
  border: none;
  background: none;
}

.verify-property-delete {
  text-align: right;
}

.begin-property-button {
  width: 100%;
}

.objection-submittied-icon {
  color: #00b2a9;
  transform: scale(1.3);
}

.objection-submittied-header {
  text-align: center;
}

.verified-property-icons {
  display: inline-flex;
}

.property-notice-details-section {
  margin-top: -10px;
}

.objection-details-text-field {
  width: 100%;
}

.land-unit-slider {
  display: inline-flex;
}

.add-sales-area {
  cursor: pointer;
  width: fit-content;
}

.add-sales-text {
  padding-left: 10px;
}

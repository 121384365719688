@import "./common-styles.scss";

header {
  font-family: $application-font;
}

body {
  margin: 0;
  font-family: $application-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $application-font;
}

@import "../../common-styles.scss";

.breadcrumb-text {
  font-family: $stepper-font-color;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #00b2a9 !important;
}

.progress-circle-text {
  width: 100%;
  text-align: center;
}

.linear-heading {
  display: inline-flex;
  width: 100%;

  .page-header-text {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    color: $helper-text-color !important;
  }

  .linear-progress-numbers {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    color: $helper-text-color !important;
  }
}

@import "./common-styles.scss";

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //background: #fff;
  z-index: 1;
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.content-wrap {
  padding-bottom: 16rem;
  position: relative;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 16rem;
  max-height: 16rem;
  background-color: $footer-color;
}

.page-sub-headings {
  background-color: $page-headings;
  padding-top: 12px;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  color: $page-headings-text;
}

.button-style {
  background-color: $button-background-color !important;
  color: $button-text-color !important;
  font-weight: bold;
  text-transform: none;
  font-size: 15px;
}

input:disabled {
  color: rgba(0, 0, 0, 0.65);
}

p.Mui-disabled {
  color: rgba(0, 0, 0, 0.65);
}

label.Mui-disabled {
  color: rgba(0, 0, 0, 0.65);
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.Mui-disabled {
  color: rgba(0, 0, 0, 0.65);
}

label.MuiInputLabel-formControl {
  color: rgba(0, 0, 0, 0.65);
}

p.MuiFormHelperText-root {
  color: rgba(0, 0, 0, 0.65);
}

.button-style:focus {
  background-color: #167a75 !important;
  color: white !important;
  font-weight: bold;
}

.button-style:hover {
  background-color: #167a75 !important;
  color: white !important;
  font-weight: bold;
}

/** overriding control styles */
.MuiTypography-body1,
.MuiTypography-body2,
.MuiTypography-body3,
.MuiInputBase-input,
.MuiInputBase-root,
.MuiFormHelperText-root,
.Mui-error,
.Mui-required,
.MuiFormLabel-root,
.MuiFormHelperText-filled {
  font-family: $application-font;
}

.MuiFormHelperText-filled.Mui-error.Mui-required,
.MuiFormHelperText-root.Mui-error {
  color: $error-text-color !important;
}

.Mui-error.Mui-required,
.required-field-error {
  color: $error-text-color !important;
}

.radio-option-label {
  padding-left: 10px;
  display: inline;
}

.MuiCircularProgress-root
  .MuiCircularProgress-colorPrimary
  .MuiCircularProgress-static {
  height: 80px !important;
  width: 80px !important;
}

.static-text-contents {
  color: $helper-text-color;
  background-color: $helper-text-background-color;
  padding-top: 20px;
  padding-bottom: 20px;
}

.errorMessages {
  color: $error-text-color;
}

.date-two-column .MuiGrid-root.MuiGrid-container {
  margin-top: -16px;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: $application-header-color;
}

.MuiInputLabel-asterisk {
  visibility: hidden;
}

@import "../../common-styles.scss";

.review-section-headings {
  border-bottom: 1px solid $helper-text-color;
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.review-page-font {
  color: $helper-text-color !important;
}

.review-page-field-value {
  font-weight: bold;
}

.review-page-field {
  display: inline-grid;
}

$application-header-color: #002855;
$white-color: #ffffff;
$button-background-color: #00c7b1;
$button-text-color: #2c3742;
$stepper-color: #00c7b1 !important;
$stepper-font-color: #53565a !important;
$stepper-complete-line-color: #00c7b1 !important;
$stepper-incomplete-line-color: #53565a !important;
$footer-color: #002855;
$helper-text-background-color: #c0ddf2;
$helper-text-color: #53565a !important;
$application-font: "Roboto", "Helvetica Neue", "Arial" !important;
$page-headings: #eaeaea;
$page-headings-text: #53565a;
$warning-box-color: #ff9e1b;
$error-color: #f44336;
$error-text-color: #db1600;

@import "../../common-styles.scss";

.objection-details-text-field {
  width: 100%;
}

.objection-details-background {
  background-color: $helper-text-background-color;
  padding-top: 10px;
  padding-bottom: 10px;
}

.objection-reason {
  overflow: hidden;
}

.objection-reason .radio-option {
  margin-bottom: 15px;
}

.custom-control-label.checkboxError {
  color: $error-color !important;
}

.objecting-values-warning {
  border: solid 2px $warning-box-color;
  border-left: solid 30px $warning-box-color;
  padding-top: 10px;
  padding-bottom: 10px;
}

.warning-outer-div {
  display: inline-flex;
  z-index: 2;
}

.warning-icon {
  z-index: 1;
  color: $white-color;
}

.respondent-select {
  margin-top: 26px;
}

.radio-list {
  display: inline-flex;
}

.radio-option {
  padding-right: 20px;
}

.radio-option-label {
  padding-left: 10px;
}

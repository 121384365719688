@import "../../common-styles.scss";

.property-type-list {
  width: 100%;
}

.land-unit-slider {
  display: inline-flex;
}

.attachment-section {
  min-height: 150px;
}

.new-attachment {
  padding-top: 20px;
  padding-left: 20px;
}

.existing-attachments {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  height: 46px;
  width: 220px;
  padding-top: 10px;
}

.addAttachmentText {
  padding-left: 20px;
  font-size: 12.96px;
}

.property-details-background {
  background-color: $helper-text-background-color;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cms-content-property-details {
  color: $helper-text-color;
  background-color: $helper-text-background-color;
  padding-top: 20px;

  strong {
    font-weight: bold;
  }

  .wp-block-columns {
    display: inline-flex;

    .wp-block-column {
      padding-right: 50px;
    }
  }
}

.unit-land-radio {
  padding-top: 10px;
  padding-left: 25px;
}

.MuiButton-label {
  text-transform: none;
}

.contended-property-layout {
  margin-top: -17px;
}

.currency-item {
  padding-right: 5px;
}

.verify-button {
  margin-top: 0px;
  margin-right: 5px;
  display: inline-flex;
  font-size: 14px;
}

.check-circle-icon-new {
  transform: scale(1.3);
  color: #00b2a9;
}

.verify-icon-begin {
  padding-left: 10px;
  font-size: 25px;
}

@import "../../common-styles.scss";

.breadcrumb-text {
  font-family: $stepper-font-color;
}

.MuiBreadcrumbs-separator {
  margin-left: 4px !important;
  margin-right: 4px !important;
  padding-top: 10px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: $stepper-color;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: $stepper-color;
}

.stepper-aligned {
  text-align: $stepper-color;
}

.MuiStepConnector-line {
  border-color: $stepper-color;
}

.MuiStepConnector-completed {
  border-color: $stepper-color;
}

@import "../../common-styles.scss";

.top-header {
  background-color: $application-header-color;
  color: $white-color;
  height: 100px;

  .logo {
    margin-top: 17px;
    height: 66px;
  }
}

.header-logo {
  height: 56px;
  width: 98px;
}
